import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';


const propTypes = {
  data: PropTypes.instanceOf(Object),
  line: PropTypes.string,
};

const defaultProps = {
  data: {},
  line: 'line',
};
const WhyArticle = ({ data, line }) => {
  const {
    title,
    paragraph,
  } = data;

  const clsLine = `whyarticle__${line}`;
  return (
    <article className="whyarticle">
      <div className={clsLine} />
      <h4 className="whyarticle__title">{title}</h4>
      <p className="whyarticle__subtitle">{paragraph}</p>
    </article>
  );
};

WhyArticle.propTypes = propTypes;
WhyArticle.defaultProps = defaultProps;
export default WhyArticle;
