import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import request from 'superagent';

import RESUME_LS_RECORD_AG from 'shared-constants/src/resumeLSRecordAG';
import parseJSON from 'shared-utils/src/parseJSON';
import SearchBuilder from 'shared-components/components/SearchBuilder';

import { getItem, setItem } from '@helpers/Storage';

import UiHandlerHOC from '../app/UiHandler';
import { fetchAgencyHome } from '../../common/redux/agencyHome/actions';
import { fetchLocationById } from '../components/common/search/helpers/search';

import AgencyHome from './AgencyHome';

const AgencyHomePageWrapper = (props) => {
  const {
    device,
    orientation,
    config: {
      siteUrl,
      esapi: esapiConfig,
      smartSuggest,
      remote: {
        LocalitiesWithAgencyZones,
      },
    },
    actions: {
      fetchAvailableResultsAgency,
    },
    agencyHome,
    agencyHome: {
      domain,
      locale,
      seoFooter,
      categoryMenuValues,
      pTypesMenuValues,
      numRoomMenuValues,
      ogData,
      isFetchingResults,
      availableResultsAgency,
    },
    modal,
    modalData,
    isShortDesktop,
    viewport,
    seoData,
    config,
    store,
    modalType,
    touchscreen,
    updateUser,
    updateUserFormData,
    user,
    userLogIn,
    userLogOut,
    appReducer,
    iosLth12,
    isLocalStorageAvailable,
    location,
    mobileOs,
  } = props;

  const [initialFilters, setInitialFilters] = useState({ channel: 'trova', agencyData: {} });
  const [initialLocations, setInitialLocations] = useState({ locations: null, parent: null });
  const [availableResults, setAvailableResults] = useState(availableResultsAgency);

  const computeAgencyAPIReq = async ({
    locations,
    activeFilters,
    agencyItem,
  }) => {
    const proxy = await request.post('/portal-srp/api/v1/apireq/agency')
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .withCredentials()
      .send({
        config: {
          domain,
          locale,
          esapiConfig,
        },
        params: {
          locations,
          activeFilters,
          agencyItem,
          type: agencyItem?.id ? 'agencyPdp' : 'agencySrp',
        },
      });
    const { body: { apireq } } = proxy;
    return apireq;
  }; 

  const fetchResults = async (locations, filters) => {
    if (!locations.length) {
      setAvailableResults('');
    } else {
      const { agencyData } = filters;
      const activeFilters = { ...filters };
      delete activeFilters.agencyData;
      const apireq = await computeAgencyAPIReq({
        locations,
        activeFilters,
        agencyItem: agencyData,
      });
      fetchAvailableResultsAgency(apireq);
    }
  };

  const fetchSBAvailableResults = async ({
    locations,
    filters,
  }) => {
    const { agencyData } = filters;
    const activeFilters = { ...filters };
    delete activeFilters.agencyData;
    Object.keys(activeFilters).map(key => !activeFilters[key] && delete activeFilters[key]);

    const apireq = await computeAgencyAPIReq({
      locations,
      activeFilters,
      agencyItem: agencyData,
    });

    const proxy = await request.post('/portal-srp/api/v1/agency/availableresultsagency')
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .withCredentials()
      .send({ apireq: apireq.apireq });

    return {
      total: proxy?.body?.availableResultsAgency || 0,
    };
  };


  const submitSearch = async (search, searchParent, filters) => {
    const { agencyData } = filters;
    const activeFilters = { ...filters };
    if (activeFilters.channel === 'trova') {
      document.location = `${siteUrl}/trova-agenzia/`;
    } else {
      delete activeFilters.agencyData;
      Object.keys(activeFilters).map(key => !activeFilters[key] && delete activeFilters[key]);

      const apireq = await computeAgencyAPIReq({
        locations: search,
        activeFilters,
        agencyItem: agencyData,
      });
      if (isLocalStorageAvailable) {
        setItem(RESUME_LS_RECORD_AG, JSON.stringify({ filters, locations: search, parentLocation: searchParent }));
      }
      document.location = apireq.uri;
    }
  };

  useEffect(
    () => {
      if (window.localStorage) {
        const lastSearch = parseJSON(getItem(RESUME_LS_RECORD_AG));
        const oldLastSearch = parseJSON(getItem('_newAgencyHPLastSearch'));

        if (lastSearch || oldLastSearch) {
          let LSinitialFilters = {};
          let LSinitialLocations = {};
  
          if (oldLastSearch && !lastSearch) {
            LSinitialFilters.agencyData = oldLastSearch.agencyItem || {};
            LSinitialLocations = {
              locations: oldLastSearch.locations || [],
              parent: oldLastSearch.parentLocation || {},
            };
          } else if (lastSearch) {
            LSinitialFilters = {
              ...lastSearch.filters,
              agencyData: lastSearch.agencyItem || {},
            };
            LSinitialLocations = {
              locations: lastSearch.locations || [],
              parent: lastSearch.parentLocation || {},
            };
          }
          setInitialFilters(LSinitialFilters);
          setInitialLocations(LSinitialLocations);
          fetchResults(LSinitialLocations.locations, LSinitialFilters);
        }
      }
    }, [],
  );

  useEffect(() => setAvailableResults(availableResultsAgency), [availableResultsAgency]);

  return (
    <SearchBuilder
      initialFilters={initialFilters}
      initialLocations={initialLocations}
      fetchLocationById={fetchLocationById}
    >
      {(searchProps) => {
        const {
          filters,
          filters: {
            agencyData,
          },
          locations: {
            search,
            searchParent,
            ui: {
              main,
              additional,
            },
          },
          setCurrentFilters,
          setCurrentLocation,
        } = searchProps;

        const setFilters = (newFilter) => {
          const oldChannel = filters.channel;
          setCurrentFilters(
            { ...filters, ...newFilter }, (updatedFilters) => {
              // è necessario chiamare la fetch results solo se
              // si passa da trova agli altri canali
              if (newFilter.channel && oldChannel === 'trova') {
                fetchResults(search, updatedFilters);
              }              
            },
          );
        };
        const setLocations = (choice, parent) => {
          setCurrentLocation(choice, parent, {}, (updatedLocations) => {
            setCurrentFilters({ ...filters, ...{ agencyData: {} } }, updatedFilters => fetchResults(updatedLocations, updatedFilters));
          });
        };

        const getAvailableResults = async ({
          newLocations = null,
          newFilters = {},
        }) => {
          const fetchParams = {
            locations: newLocations || search,
            filters: newFilters || filters,
            // filters: { ...filters, ...newFilters },
          };
          const av = await fetchSBAvailableResults(fetchParams);
          return av;
        };


        const submit = () => submitSearch(search, searchParent, filters);

        const hasAgency = agencyData.name;
        const mainHasZones = main && main.level === 9 && LocalitiesWithAgencyZones.indexOf(main.slug) > -1;

        return (
          <AgencyHome
            filters={filters}
            agencyData={agencyData}
            search={search}
            searchParent={searchParent}
            main={main}
            additional={additional}
            setFilters={setFilters}
            setLocations={setLocations}
            submit={submit}
            hasAgency={hasAgency}
            mainHasZones={mainHasZones}
            device={device}
            orientation={orientation}
            smartSuggest={smartSuggest}
            LocalitiesWithAgencyZones={LocalitiesWithAgencyZones}
            domain={domain}
            seoFooter={seoFooter}
            categoryMenuValues={categoryMenuValues}
            pTypesMenuValues={pTypesMenuValues}
            numRoomMenuValues={numRoomMenuValues}
            ogData={ogData}
            modal={modal}
            modalData={modalData}
            isShortDesktop={isShortDesktop}
            viewport={viewport}
            seoData={seoData} 
            isFetchingResults={isFetchingResults}
            availableResults={availableResults}
            getAvailableResults={getAvailableResults}
            config={config}
            store={store}
            modalType={modalType}
            touchscreen={touchscreen}
            updateUser={updateUser}
            updateUserFormData={updateUserFormData}
            user={user}
            userLogIn={userLogIn}
            userLogOut={userLogOut}
            appReducer={appReducer}
            iosLth12={iosLth12}
            isLocalStorageAvailable={isLocalStorageAvailable}
            location={location}
            mobileOs={mobileOs}
            agencyHome={agencyHome}
          />
        );
      }}
    </SearchBuilder>
  );
};

AgencyHomePageWrapper.propTypes = {
  device: PropTypes.string,
  orientation: PropTypes.string,
  actions: PropTypes.instanceOf(Object),
  agencyHome: PropTypes.instanceOf(Object),
  modal: PropTypes.bool,
  modalData: PropTypes.instanceOf(Object),
  isShortDesktop: PropTypes.bool,
  viewport: PropTypes.instanceOf(Object),
  seoData: PropTypes.instanceOf(Object),
  config: PropTypes.instanceOf(Object),
  store: PropTypes.instanceOf(Object),
  modalType: PropTypes.string,
  touchscreen: PropTypes.bool,
  updateUser: PropTypes.func,
  updateUserFormData: PropTypes.func,
  user: PropTypes.instanceOf(Object),
  userLogIn: PropTypes.func,
  userLogOut: PropTypes.func,
  appReducer: PropTypes.func,
  iosLth12: PropTypes.bool,
  isLocalStorageAvailable: PropTypes.bool,
  location: PropTypes.instanceOf(Object),
  mobileOs: PropTypes.string,
};

AgencyHomePageWrapper.defaultProps = {
  device: '',
  orientation: '',
  actions: {},
  agencyHome: {},
  modal: false,
  modalData: {},
  isShortDesktop: false,
  viewport: {},
  seoData: {},
  config: {},
  store: {},
  modalType: '',
  touchscreen: false,
  updateUser: () => {},
  updateUserFormData: () => {},
  user: {},
  userLogIn: () => {},
  userLogOut: () => {},
  appReducer: () => {},
  iosLth12: false,
  isLocalStorageAvailable: false,
  location: {},
  mobileOs: '',
};

const AgencyHomePage = UiHandlerHOC(AgencyHomePageWrapper, fetchAgencyHome);
export default AgencyHomePage;
