import React from 'react';
import PropTypes from 'prop-types';

import trackGTMEvents from 'shared-utils/src/trackGTMEvents';

import Icon from 'shared-vectors/src/components/Icon';
import Caret from 'shared-vectors/icons/Caret';

import './style.scss';

const propTypes = {
  data: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {},
};
const Banner = ({ data }) => {
  const {
    title,
    subtitleOne,
    subtitleTwo,
    img,
    href,
    name,
  } = data;

  const trackMandate = () => {
    if (href === '/valutazione-immobile/') {
      trackGTMEvents(
        'Interaction',
        'PushButton',
        'GoToValuation_AgencySearchHP',
      );
    }
  };
  return (
    <a className="banner" href={href} title={name} onClick={trackMandate}>
      <img className="banner__icon-left" src={img} alt={name} />
      <div className="banner__containaer_descr">
        <h3 className="banner__title">{title}</h3>
        <p className="banner__subtitle">{subtitleOne}</p>
        <p className="banner__subtitle">{subtitleTwo}</p>
      </div>
      <Icon className="banner__caret" name={Caret} width="16px" height="16px" />
    </a>
  );
};

Banner.propTypes = propTypes;
Banner.defaultProps = defaultProps;
export default Banner;
