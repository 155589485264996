import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@components/common/Icon';
import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import { useCtxGlobal } from '@client/app/Container';
import { createAgencyGroupModal } from '@components/modals/factories';

const propTypes = {
  locations: PropTypes.instanceOf(Array),
  filters: PropTypes.instanceOf(Object),
  agencyItem: PropTypes.instanceOf(Object),
  parentLocation: PropTypes.instanceOf(Array),
  setFilters: PropTypes.func,
};

const defaultProps = {
  locations: [],
  filters: {},
  agencyItem: {},
  parentLocation: {},
  setFilters: () => { },
};

const SearchAgencyNameHero = ({
  locations,
  parentLocation,
  agencyItem,
  filters,
  setFilters,
}) => {
  const { openModal, isMobile } = useCtxGlobal();

  const { name: agencyName = '' } = agencyItem;
  const labelTxt = '';
  const buttonTxt = agencyName;

  const deleteAgency = (ev) => {
    ev && ev.preventDefault();
    ev && ev.stopPropagation();
    setFilters({ agencyData: {} });
  };

  const openAgengySearch = () => openModal(createAgencyGroupModal({
    locations,
    parentLocation,
    filters,
    isMobile,
    setFilters,
  }));

  return (
    <Grid className="hpFormOption childlocality agname" align="baseline" noWrap>
      <GridItem behavior="fixed" className="hpFormLabel">{labelTxt}</GridItem>
      <GridItem behavior="fixed" className="tp--ell hpFormInput withErase">
        {
          !!buttonTxt &&
          <>
            <Grid className="hpFormInputButtonGrid is-clickable" onClick={openAgengySearch}>
              <GridItem className="tp--ell">{buttonTxt}</GridItem>
              <GridItem behavior="fixed" onClick={deleteAgency} className="pl--ml">
                <Icon name="close-l" size="m" className="mb--xs" />
              </GridItem>
            </Grid>
          </>
        }
      </GridItem>
    </Grid>
  );
};

SearchAgencyNameHero.propTypes = propTypes;
SearchAgencyNameHero.defaultProps = defaultProps;
export default SearchAgencyNameHero;
