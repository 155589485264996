export const whyarticles = [{
  title: 'Una consulenza completa',
  paragraph: 'L\'agenzia ti accompagna nel percorso di ricerca e di vendita della casa, mettendoti a disposizione tutta l’esperienza e le competenze nel settore, aiutandoti a fare la scelta giusta e seguendoti in tutto il processo di compravendita.',
},
{
  title: 'Quando si cerca casa',
  paragraph: 'L\'agenzia conosce bene la tua zona e ti può proporre le soluzioni più adatte alle tue esigenze. Ti segue durante tutte le fasi della compravendita, ti accompagna durante le visite, fa da mediatore con il proprietario di casa e cura gli aspetti burocratici.',
}, 
{
  title: 'Quando si vende casa',
  paragraph: 'Sa calcolare il valore di mercato dell’immobile, sa valorizzare al meglio i suoi punti forza, si occupa del servizio fotografico e della pubblicità per farti trovare il giusto acquirente, organizza le visite e ti segue fino alla transazione.',
},
];
