export const banners = [{
  title: 'Trova la tua nuova casa',
  subtitleOne: 'Cerca l\'immobile giusto per te',
  subtitleTwo: 'tra oltre un milione di annunci!',
  name: 'homeSearch',
  href: '/',
  img: '/portal-srp/assets/img/agency/HomeSearch.svg',
},
{
  title: 'Valuta la tua casa',
  subtitleOne: 'Sai quanto vale la tua casa?',
  subtitleTwo: 'Scoprilo subito è gratis!',
  name: 'homePrice',
  href: '/valutazione-immobile/',
  img: '/portal-srp/assets/img/agency/HomePrice.svg',

}];
