/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PAGES_TYPE from 'shared-constants/src/pagesType';

import trackGTMEvents from 'shared-utils/src/trackGTMEvents';

import reducerSearch from '@state/reducers/search';
import selectorSearch from '@state/selectors/search';
import GoToTop from '@async-components/GoToTop';
import { CtxContainer } from '@client/app/Container';
import SearchAgencyNameHero from '@components/agencyHomePage/SearchAgencyNameHero';
import { getActiveFilters, getActiveFiltersCountWithPTypes } from '@filters/helpers';

import App from '../app/App';
import MetaInfo from '../components/common/metainfo/MetaInfo';

import Select from '../components/homePage/Select';
import Search from '../components/homePage/Search';
import ButtonOpenModal from '../components/homePage/ButtonOpenModal';
import SearchChildrenLocalities from '../components/homePage/SearchChildrenLocalities';
import LabelSelect from '../components/agencyHomePage/LabelSelect';
import SearchButton from '../components/homePage/SearchButton';
import AnimatedSplash from '../components/homePage/AnimatedSplash';
import SeoFooter from '../components/homePage/SeoFooter';
import BannerContainer from '../components/agencyHomePage/BannerContainer';
import WhyAgencyContainer from '../components/agencyHomePage/WhyAgencyContainer';

import { isChildOf, scrollToTop } from '../helpers/DOMHelpers';

import '../home/HomePageMore-critical.scss';
import '../home/HomePageSearch-critical.scss';
import './AgencyHomePage-critical.scss';

import { categoryFilter, agzChannelFilter } from '../constants/restyling';

class AgencyHomePageComponent extends PureComponent {
  static contextType = CtxContainer;

  constructor(props) {
    super(props);
    this.state = {
      qsInfocus: false,
      openSelect: null,
      config: props.config,
    };
  }

  componentDidMount() {
    this.locationInput = document.querySelector('#locationInput');
    this.searchButtonEl = document.querySelector('#searchButton');
    this.footerEl = document.querySelector('.csa_footer');
    this.header = document.querySelector('.csa_header');
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.device === 'desktop') {
      window.addEventListener('click', evt => this.searchElementsBehaviour(evt));
    } else {
      document.documentElement.style.setProperty('--minHeight', `${nextProps.viewport.height}px`);
    }
  }

  closeSearch = () => {
    this.setState({ qsInfocus: false });
  }

  searchElementsBehaviour = (evt) => {
    const {
      device,
    } = this.props;
    const { qsInfocus } = this.state;
    if (
      qsInfocus
      && evt.target !== this.locationInput
      && !isChildOf(evt.target, this.locationInput)      
      && !evt.target.parentNode.classList.contains('locationInput__loc-in')
      && !evt.target.classList.contains('hpFormInputButton')
      && device !== 'smartphone'
    ) {
      this.closeSearch();
    }
  }

  onToggleSelect = (which) => {
    const { openSelect } = this.state;

    if (openSelect !== null) {
      if (which !== openSelect) {
        this.setState({
          openSelect: which,
          qsInfocus: false,
        });
      } else {
        this.setState({
          openSelect: null,
        });
      }
    } else {
      this.setState({
        openSelect: which,
        qsInfocus: false,
      });
    }
  }

  storeSelectValue = (newFilters) => {
    const { setFilters } = this.props;
    setFilters(newFilters);
    this.setState({ openSelect: null });
  }

  onSearchInputFocus = () => {
    this.setState({ qsInfocus: true });
  }

  updateGlobalState = (state, clb) => {
    this.setState(state, clb);
  }

  globalReducer = async (type, payload) => {
    const newState = await reducerSearch(this.state, type, payload);
    this.setState(newState);
  }

  globalSelector = type => selectorSearch(this.state, type)

  render() {
    const {
      device,
      orientation,
      smartSuggest,
      LocalitiesWithAgencyZones,
      domain,
      seoFooter,
      categoryMenuValues,
      pTypesMenuValues,
      numRoomMenuValues,
      ogData,
      modal,
      modalData,
      isShortDesktop,
      viewport,
      seoData,
      filters,
      agencyData,
      search,
      searchParent,
      main,
      additional,
      setFilters,
      setLocations,
      submit,
      hasAgency,
      mainHasZones,
      isFetchingResults,
      availableResults,
      getAvailableResults,
    } = this.props;

    const { qsInfocus, openSelect } = this.state;

    const pageData = {
      ogData: ogData || [],
      filtersProps: {
        device,
        orientation,

        isAgencyHome: true,
        isPublisherPage: false,
        isPublisherHP: false,
        isAgencySrp: false,

        publisherName: null,
        publisherId: null,
        publisherLocs: [],
        publisherLocsNumber: 0,

        agencyFreetext: null,
        localitiesWithAgencyZones: LocalitiesWithAgencyZones,

        filters: {
          ...filters,
          channel: filters.channel === 'affitto' ? 'affitti' : filters.channel,
        },
        locations: search,
        parentLocation: searchParent,
        mainLocation: main,
        additionalLocations: additional,
        selNearBy: null,
        setFilters,
        setLocations,
        setCurrentNearby: () => {},
        availableResults,
        getAvailableResults,
        ptgrpsAggregates: null,
        submitSearch: submit,
        getActiveFilters,
        getActiveFiltersCountWithPTypes,
        hpValuesReset: () => alert('implementare hpValuesReset'),
        startWithSelectedPtypes: false,
        ptypesSelectFromModal: () => alert('implementare ptypesSelectFromModal'),
      },
    };

    const appProps = {
      ...this.props,
      home: {},
      search: {},
      searchMap: {},
      agentPro: {},
      cercacasa: {},
      deactivationAlerts: {},
      manageConsents: {},
      pageData,
      pageType: PAGES_TYPE.HOME_PAGE,
      updateGlobalState: this.updateGlobalState,
      globalState: this.state,
      globalReducer: this.globalReducer,
      globalSelector: this.globalSelector,
      trackEvents: trackGTMEvents,
    };

    let splashClassName = 'agenzia';
    let searchContainerClassName = device === 'tablet' ? 'tablet' : '';
    if (qsInfocus) {
      splashClassName += ' choosingLocality';
      searchContainerClassName += ' choosing';
    }
    const localityModal = modal && 'parentLocation' in modalData;
    const gototopCondition = device !== '' && device !== 'smartphone';

    const prebuttonEl = () => {
      let cont = null;
      if (filters.channel === 'trova') {
        cont = (          
          <p className="agNameBtnCtn__find tp-s--l tp-w--m c-txt--w tp-a--c">
            Selezioneremo per te le migliori agenzie per vendere la tua casa.
          </p>
        );
      } else if (main && !hasAgency) {
        cont = (
          <ButtonOpenModal
            label="nome agenzia..."
            modalType="agencySearch"
            className="agencySearch"
            locations={search}
            filters={filters}
            parentLocation={searchParent}
            setFilters={setFilters}
          />
        );
      }
      if (cont) {
        return (
          <div className="agNameBtnCtn noVertSpace">
            {cont}
          </div>
        );
      }
      return null;
    };

    return (
      <App {...appProps}>
        <MetaInfo
          domain={domain}
          seoData={seoData}
        />
        <div
          id="splash"
          onClick={(e) => { if (device !== 'desktop') { this.searchElementsBehaviour(e); } }}
          className={splashClassName}
        >
          <AnimatedSplash category="agenzia" />
          <div id="searchContainer" className={searchContainerClassName}>
            <LabelSelect
              scope="category"
              label="Cerco"
              value="Agenzia"
            />
            <Select
              scope="channel"
              label="per"
              value={filters.channel || 'trova'}
              matchValue=""
              values={agzChannelFilter}
              isOpen={openSelect === 'channel'}
              toggleSelect={this.onToggleSelect}
              storeSelectValue={this.storeSelectValue}
            />
            {filters.channel !== 'trova' && (
              <>
                {!!hasAgency && (
                  <SearchAgencyNameHero
                    locations={search}
                    parentLocation={searchParent}
                    filters={filters}
                    agencyItem={agencyData}
                    setFilters={setFilters}
                  />
                )}
                <Search
                  device={device}
                  mainLocation={main}
                  qsInfocus={qsInfocus}
                  smartSuggest={smartSuggest}
                  isShortDesktop={isShortDesktop}
                  localitiesWithAgencyZones={LocalitiesWithAgencyZones}
                  setLocations={setLocations}
                  onSearchInputFocus={this.onSearchInputFocus}
                  closeSearch={this.closeSearch}
                  isAgencyHome
                />
                {
                  additional
                  && !qsInfocus
                  && !localityModal
                  && mainHasZones
                  && (
                    <SearchChildrenLocalities
                      filters={filters}
                      locations={additional}
                      parentLocation={main}
                      setLocations={setLocations}
                      isAgencyHome
                    />
                  )
                }              
              </>
            )}
            {prebuttonEl()}
            <SearchButton
              isAgencyHome
              agencyType={agencyData?.id ? 'agencyPdp' : 'agencySrp'}
              device={device}
              categoryValues={categoryFilter}
              qsInfocus={qsInfocus}
              availableResults={availableResults}
              submitSearch={submit}
              isFetching={isFetchingResults}
              locations={search}
              follow={filters.channel === 'trova'}
            />
          </div>
        </div>
        <BannerContainer />
        <WhyAgencyContainer />
        <SeoFooter
          device={device}
          orientation={orientation}
          channel={filters.tr}
          category={filters.category}
          propertyType={filters.propertyType}
          numRooms={filters.numRooms}
          buildingCondition={'buildingCondition' in filters ? filters.buildingCondition : null}
          seoFooter={seoFooter}
          categoryMenuValues={categoryMenuValues}
          pTypesMenuValues={pTypesMenuValues}
          numRoomMenuValues={numRoomMenuValues}
          isAgencyHome
          seoFooterBigCities={{}}
        />
        {gototopCondition && <GoToTop action={scrollToTop} vh={viewport?.height || 0} />}
      </App>
    );
  }
}

AgencyHomePageComponent.propTypes = {
  filters: PropTypes.instanceOf(Object),
  agencyData: PropTypes.instanceOf(Object),
  search: PropTypes.instanceOf(Array),
  searchParent: PropTypes.instanceOf(Object),
  main: PropTypes.instanceOf(Object),
  additional: PropTypes.instanceOf(Array),
  setFilters: PropTypes.func,
  setLocations: PropTypes.func,
  submit: PropTypes.func,
  hasAgency: PropTypes.bool,
  mainHasZones: PropTypes.bool,
  device: PropTypes.string,
  orientation: PropTypes.string,
  smartSuggest: PropTypes.instanceOf(Object),
  LocalitiesWithAgencyZones: PropTypes.instanceOf(Array),
  domain: PropTypes.string,
  seoFooter: PropTypes.instanceOf(Object),
  categoryMenuValues: PropTypes.instanceOf(Array),
  pTypesMenuValues: PropTypes.instanceOf(Array),
  numRoomMenuValues: PropTypes.instanceOf(Array),
  ogData: PropTypes.instanceOf(Object),
  modal: PropTypes.bool,
  modalData: PropTypes.instanceOf(Object),
  isShortDesktop: PropTypes.bool,
  viewport: PropTypes.instanceOf(Object),
  seoData: PropTypes.instanceOf(Object),
  isFetchingResults: PropTypes.bool,
  availableResults: PropTypes.string,
  config: PropTypes.instanceOf(Object),
  store: PropTypes.instanceOf(Object),
  modalType: PropTypes.string,
  touchscreen: PropTypes.bool,
  updateUser: PropTypes.func,
  updateUserFormData: PropTypes.func,
  user: PropTypes.instanceOf(Object),
  userLogIn: PropTypes.func,
  userLogOut: PropTypes.func,
  appReducer: PropTypes.func,
  iosLth12: PropTypes.bool,
  isLocalStorageAvailable: PropTypes.bool,
  location: PropTypes.instanceOf(Object),
  mobileOs: PropTypes.string,
};
AgencyHomePageComponent.defaultProps = {
  filters: {},
  agencyData: {},
  search: [],
  searchParent: {},
  main: {},
  additional: [],
  setFilters: () => {},
  setLocations: () => {},
  submit: () => {},
  hasAgency: false,
  mainHasZones: false,
  device: '',
  orientation: '',
  smartSuggest: {},
  LocalitiesWithAgencyZones: [],
  domain: '',
  seoFooter: {},
  categoryMenuValues: [],
  pTypesMenuValues: [],
  numRoomMenuValues: [],
  ogData: {},
  modal: false,
  modalData: {},
  isShortDesktop: false,
  viewport: {},
  seoData: {},
  isFetchingResults: false,
  availableResults: '',
  config: {},
  store: {},
  modalType: '',
  touchscreen: false,
  updateUser: () => {},
  updateUserFormData: () => {},
  user: {},
  userLogIn: () => {},
  userLogOut: () => {},
  appReducer: () => {},
  iosLth12: false,
  isLocalStorageAvailable: false,
  location: {},
  mobileOs: '',
};
export default AgencyHomePageComponent;
