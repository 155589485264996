import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  scope: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
};

const defaultProps = {
  scope: '',
  label: '',
  value: '',
};


const LabelSelect = ({
  scope,
  label,
  value,
}) => (
  <div
    id={`${scope}Select`}
    className={`hpFormOption ${scope}`}
  >
    <p className="hpFormLabel">{label}</p>
    <p className="hpFormLabelValue">{value}</p>
  </div>
);
LabelSelect.propTypes = propTypes;
LabelSelect.defaultProps = defaultProps;
export default LabelSelect;
