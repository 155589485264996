import React from 'react';

import './style.scss';
import Banner from './Banner';
import { banners } from './messages';

export default function BannerContainer() {

  return (
    <section className="banner-container">
      {banners.map(b => <Banner data={b} key={b.title} />)}
    </section>
  );
}
