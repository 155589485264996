import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import HpPencil from 'shared-vectors/icons/HpPencil';
import { Icon } from 'shared-vectors';

import { isValidString } from 'shared-utils/src/checkVarType';

import ResumeMapSearch from '@async-components/ResumeMapSearch';

import { CtxContainer } from '@client/app/Container';
import { getNearByName, getNearByMin, ensureNearBy } from '@helpers/dataModels/poi';
import { createNearByModal, queryBuilder } from '@modals/factories';
import { makeClassName } from '@helpers';

import './Search.scss';

const propTypes = {
  qsInfocus: PropTypes.bool,
  mainLocation: PropTypes.instanceOf(Object),
  filters: PropTypes.instanceOf(Object),
  nearby: PropTypes.instanceOf(Object),
  closeSearch: PropTypes.func,
  setLocations: PropTypes.func,
  setCurrentNearby: PropTypes.func,
  localitiesWithAgencyZones: PropTypes.instanceOf(Array),
  setMapDialog: PropTypes.func,
  submitSearch: PropTypes.func,
};

const defaultProps = {
  qsInfocus: false,
  mainLocation: null,
  filters: {},
  nearby: null,
  closeSearch: () => { },
  setLocations: () => { },
  setCurrentNearby: () => { },
  localitiesWithAgencyZones: [],
  setMapDialog: () => { },
  submitSearch: () => { },
};

export const CtxSearch = React.createContext();
export const useCtxSearch = () => useContext(CtxSearch);

class Search extends React.PureComponent {
  static contextType = CtxContainer;

  closeSearch = () => {
    const {
      closeSearch,
    } = this.props;
    closeSearch();
  }


  blurAdvancedSearchInputOnSuggScroll = () => {
    this.advancedLocationInput.blur();
  }

  closeSearchAndCleanQs = () => {
    const {
      closeSearch,
    } = this.props;
    closeSearch();
  }

  createMainLocalityLabel = (mainLocation, selected) => {
    let label = 'a';
    if (selected) {
      const min = getNearByMin(selected);
      label = `${min ? `a ${min} min. ` : ''}da`;
    } else if (mainLocation) {
      const { level } = mainLocation;
      if (level === 6) {
        label = 'in';
      } else if (level === 118) {
        label = 'sulla';
      }
    }
    return label;
  }

  createMainLocalityButton = (mainLocation) => {
    if (mainLocation) {
      const { name, level } = mainLocation;
      if (name) {
        return level === 6
          ? `${name} (provincia)`
          : name;
      }
    }
    return 'Scegli dove';
  }

  openQB = (resetMapData = false) => {
    const {
      localitiesWithAgencyZones,
      setLocations,
      setCurrentNearby,
      submitSearch,
    } = this.props;
    const {
      isMobile,
      isSmallMobile,
      openModal,
      filtersProps,
      filtersProps: {
        isAgencyHome,
      },
      pageType,
    } = this.context;

    const qbConf = {
      isMobile,
      isSmallMobile,
      pageType,
      isAgency: isAgencyHome,
      localitiesWithAgencyZones,
      setLocations,
      setCurrentNearby,
      filtersProps,
      submitSearch,
      panelChooseBy: {
        isMobile,
        actionClicked: isAgencyHome ? 'agzMainLocalityBox' : 'mainLocalityBox',
        pageFrom: pageType,
      },
    };

    if (resetMapData) {
      qbConf.resetMapData = true;
    }

    openModal && openModal(queryBuilder(qbConf));
  };

  onSearchInputFocus = () => {
    const {
      mainLocation,
      setMapDialog,
      submitSearch,
    } = this.props;

    const {
      isMobile,
      filtersProps,
    } = this.context;
    if (mainLocation && mainLocation.level === 999) {
      const { filters } = filtersProps;
      const closeMapDialog = () => setMapDialog(null);
      const openQB = () => this.openQB(true);
      setMapDialog(
        <ResumeMapSearch
          isMobile={isMobile}
          filters={filters}
          openQB={openQB}
          submitSearch={submitSearch}
          onClose={closeMapDialog}
        />,
      );
    } else {
      this.openQB();
    }
  }

  openNearByModal = () => {
    const {
      setCurrentNearby,
      filters: {
        nearby,
      },
    } = this.props;
    const { openModal, isMobile } = this.context;
    openModal && openModal(createNearByModal({
      from: 'SearchNearByHP',
      isMobile,
      selNearby: nearby,
      setCurrentNearby,
    }));
  }

  render() {
    const { globalSelector, isMobile } = this.context;
    const {
      qsInfocus,
      mainLocation,
      filters: {
        nearby,
      },
    } = this.props;

    const showLocations = mainLocation !== null && !qsInfocus && !nearby;
    const locationsLabel = this.createMainLocalityButton(mainLocation);
    const iconPencilSize = isMobile
      ? '16px'
      : '18px';
    const iconPencil = <Icon name={HpPencil} width={iconPencilSize} height={iconPencilSize} className="mb--xs" fill />;
    const cls = makeClassName([
      'hpFormOption mainlocality',
      qsInfocus ? 'active' : '',
    ]);
    const formInputCls = makeClassName([
      'hpFormInput', // hpFormInput--no-h
      // showLocations ? 'withErase autoWidth' : '',
      showLocations ? 'autoWidth' : '',
      nearby ? 'hpFormInput--loc' : '',
    ]);

    let selectedNearBy = null;
    if (nearby) {
      selectedNearBy = isValidString(nearby?.valueName)
        ? nearby
        : ensureNearBy(nearby, globalSelector('nearby'));
    }

    return (
      <Grid id="locationInput" className={cls} noWrap>
        <GridItem behavior="fixed" className="hpFormLabel">
          {this.createMainLocalityLabel(mainLocation, selectedNearBy)}
        </GridItem>
        <GridItem behavior="fixed" className={`tp--ell ${formInputCls}`}>
          <Grid className="locationInput__loc-in is-clickable" onClick={this.onSearchInputFocus}>
            <GridItem className={`tp--ell${!showLocations && !nearby ? ' hpFormInputPlaceHolder' : ''}`}>
              {selectedNearBy ? getNearByName(selectedNearBy, globalSelector('nearby')) : locationsLabel}
            </GridItem>
            <GridItem behavior="fixed" className="locationInput__loc--agz pl--m">
              {iconPencil}
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    );
  }
}
Search.propTypes = propTypes;
Search.defaultProps = defaultProps;
export default Search;
