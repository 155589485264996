/* eslint-disable react/prop-types */
import React from 'react';
import { useCtxGlobal } from '@client/app/Container';
import { createPoiModal, createAgencyGroupModal } from '@modals/factories';
import './style.scss';

export default ({ 
  className = '',
  style = {},
  label = '',
  modalType = '',
  filters = {},
  locations = [],
  parentLocation = [],
  setFilters = () => {},
}) => {
  const { openModal, isMobile } = useCtxGlobal();
  const createModal = modalType === 'agencySearch' ? createAgencyGroupModal : createPoiModal;
  const handleClick = () => {
    openModal && openModal(createModal({
      isMobile,
      locations,
      parentLocation,
      filters,
      setFilters,
    }));
  };
  const cls = [
    className,
    'poiLaunch tp-w--m tp-s--xs c-bg--w b-r--m pl--s pr--s c-txt--f1',
  ].join(' ').trim();
  return (
    <button type="button" className={cls} style={style} onClick={handleClick}>
      {label}
    </button>
  );
};
