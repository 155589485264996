import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import HpPencil from 'shared-vectors/icons/HpPencil';
import { Icon } from 'shared-vectors';

import { useCtxGlobal } from '@client/app/Container';
import { createLocalities, queryBuilder } from '@modals/factories';
import { replaceAll } from '@helpers/STRINGHelpers';

const propTypes = {
  filters: PropTypes.instanceOf(Object),
  locations: PropTypes.instanceOf(Array),
  parentLocation: PropTypes.instanceOf(Object),
  setLocations: PropTypes.func,
  isAgencyHome: PropTypes.bool,
  submitSearch: PropTypes.func,
};

const defaultProps = {
  filters: {},
  locations: [],
  parentLocation: {},
  setLocations: () => { },
  isAgencyHome: false,
  submitSearch: () => { },
};

const computeContents = (locations, parentLocation, isMobile) => {
  let suffix = '';
  let text = '';
  // let deleteBtn = false;

  const first = locations.length > 0 ? replaceAll(locations[0].name, [',', '/'], [' ·', ' ·']) : null;
  const others = locations.length > 1 ? locations.length - 1 : null;

  const isProvince = parentLocation.level === 6;
  const isMetro = parentLocation.level === 118;

  if (!locations.length) {
    suffix = isMetro ? 'intorno a' : 'in';
    if (isProvince) {
      text = 'Tutta la provincia';       
    } else if (isMetro) {
      text = 'Tutte le fermate';
    } else {
      text = 'Tutto il comune';
    }

  } else {
    suffix = isProvince ? 'nel com. di' : 'in zona';
    if (isMetro) {
      suffix = `intorno all${others ? 'e' : 'a'} fermat${others ? 'e' : 'a'}`;
    }

    const toshow = first.length > 30 && !isMobile
      ? `${first.substring(0, 27)}...`
      : first;

    text = others
      ? <span className={`firstloc${others > 9 ? ' doubledigits' : ''}`} data-more={`+${others}`}>{toshow}</span>
      : toshow;
    // deleteBtn = true;
  }

  const textEl = (
    <>
      <GridItem className="tp--ell">{text}</GridItem>
      {others && <GridItem behavior="fixed" className="ml--s">+{others}</GridItem>}
    </>
  );

  return {
    suffix,
    textEl,
    // deleteBtn,
  };
};

const SearchChildrenLocalities = ({
  filters,
  locations,
  parentLocation,
  isAgencyHome,
  setLocations,
  submitSearch,
}) => {
  const {
    openModal,
    isMobile,
    isSmallMobile,
    filtersProps,
    pageType, 
  } = useCtxGlobal();
  const {
    suffix,
    textEl,
    // deleteBtn,
  } = computeContents(locations, parentLocation, isMobile);

  // const deleteAll = () => setLocations([parentLocation], parentLocation);

  const openLocations = () => {
    if (isAgencyHome) {
      return openModal(createLocalities({
        isMobile,
        filters,
        parentLocation,
        selected: locations,
        isAgency: isAgencyHome,
        setLocations,
      }));
    }
    return openModal(queryBuilder({
      isMobile,
      isSmallMobile,
      filtersProps,
      submitSearch,
      panelChooseBy: {
        isMobile,
        actionClicked: 'childrenLocalities',
        pageFrom: pageType,
      },
    }));
  };

  const iconPencilSize = isMobile
    ? '16px'
    : '18px';

  return (
    <Grid className="hpFormOption childlocality" noWrap>
      <GridItem behavior="fixed" className="hpFormLabel">
        {suffix}
      </GridItem>
      <GridItem behavior="fixed" className="hpFormInput autoWidth tp--ell">
        <Grid className="hpFormInputButtonGrid is-clickable" onClick={openLocations}>
          {textEl}
          <GridItem behavior="fixed" className="pl--m">
            <Icon name={HpPencil} width={iconPencilSize} height={iconPencilSize} className="mb--xs" fill />
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  );
};

SearchChildrenLocalities.propTypes = propTypes;
SearchChildrenLocalities.defaultProps = defaultProps;
export default SearchChildrenLocalities;
