import React from 'react';

import './style.scss';
import WhyArticle from './WhyArticle';
import { whyarticles } from './messages';

export default function WhyAgencyContainer() {
  return (
    <section className="whyagency">
      <h3 className="whyagency__title">Perché scegliere un&apos;agenzia?</h3>
      <div className="whyagency__line" />
      <div className="whyarticle__container">
        {whyarticles.map((w, i) => <WhyArticle data={w} key={w.title} line={i === 0 ? 'noline' : 'line'} />)}
      </div>
    </section>
  );
}
