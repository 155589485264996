import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppRoot from '../agencyHome/AgencyHomePage';
import * as agencyHomeActions from '../../common/redux/agencyHome/actions';
// import * as userActions from '../../common/redux/user/actions';

function mapStateToProps(state) {
  return {
    ...state,
  };
}

// we can merge multiple actions
const actions = [
  agencyHomeActions,
  // userActions,
];

function mapDispatchToProps(dispatch) {
  const getActionFunctions = (act) => {
    let resultObj = {};
    act.map((action) => {
      for (const key in action) {
        if (typeof action[key] === 'function') {
          resultObj = Object.assign({}, resultObj, { [key]: action[key] });
        }
      }
      return action;
    });

    return resultObj;
  };

  const creators = getActionFunctions(actions);

  return {
    actions: bindActionCreators(creators, dispatch),
    dispatch,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppRoot));
